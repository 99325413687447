import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Form } from "react-bootstrap";

import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { LanguageContext } from '../context/LanguageContext';
import Lang from '../utils/Lang'
import Loading from './Loading';
export default function EditProd({ prData, onSuccess, refreshDetail }) {
    const { language } = useContext(LanguageContext);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const auth = useContext(AuthContext);
    ;
    const fetchContext = useContext(FetchContext);
    const [loadingMsg, setLoadingMsg] = useState('')

    const [refresh, setRefresh] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [prodObject, setProdObject] = useState(prData);


    const [pictures, setPictures] = useState([])
    const [file, setFile] = useState([]);
    const [disableBtn, setDisableBtn] = useState(false)
    console.log(prData)
    useEffect(() => {

    }, [prData, prodObject]);

    function handleInputChange(event) {

        const { name, value } = event.target;
        console.log(name, value)

        setProdObject({ ...prodObject, [name]: value });


    }
    function deletefile(e) {
        // console.log(e.target.name)
        console.log(e)
        const name = e

        let pics = pictures.filter(picture => picture.filename !== name)
        let fils = file.filter(fil => fil.name !== name)
        setPictures(pics)
        setFile(fils)

        setDisableBtn(false)

    }

    async function handleSumbit(e) {
        e.preventDefault();

        setIsLoading(true)
        setLoadingMsg('Updating Database')
        try {
            await fetchContext.authAxios.post("/noteupdate", prodObject).then((res) => {
                console.log(res)
                if (res.data.message === 'Session Expired') {
                    auth.logout()
                }
                setLoadingMsg("Success!")
                setTimeout(function () {
                    if (onSuccess) {
                        refreshDetail()
                    } else {
                        refreshDetail()
                    }
                    handleClose()
                    setIsLoading(false)
                }, 2000);
                setRefresh(false);



            });
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            <Button className='btn-red mb-2 float-end  btn-txt' onClick={handleShow}>
                {Lang.add_note[`${language}`]}
            </Button>
            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title> {Lang.add_note[`${language}`]}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <strong>{prData["Description(1)"]}</strong>   -   {prData["Supplier Item Num(1)"]}
                    {isLoading ?
                        <>
                            <Loading />
                            <p className='text-center'>{loadingMsg}</p>
                        </>

                        : <>
                            <Form onSubmit={(e) => handleSumbit(e)} className="formTxt">


                                <Form.Group className='' controlId="formDesc">
                                    <Form.Label>Notes *</Form.Label>
                                    <Form.Control

                                        as="textarea"
                                        rows={3}
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Notes"
                                        name="notes"
                                    />
                                </Form.Group>

                            </Form>
                            <text>* <strong>Required field</strong> <br />
                                {Lang.astrick[`${language}`]}</text>
                        </>}
                </Modal.Body>

                <Modal.Footer className='justify-content-between'>
                    {isLoading ? '' : <>                 <Button className='btn-red mb-2   btn-txt' onClick={handleClose}>
                        Cancel
                    </Button>
                        <Button className='btn-red mb-2 float-end btn-hover  btn-txt' onClick={(e) => handleSumbit(e)}>
                            {Lang.submit_new_changes[`${language}`]}
                        </Button></>}

                </Modal.Footer>
            </Modal>
        </>
    );
}
