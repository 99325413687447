import React, { useState } from 'react';
import ImageViewer from '../components/ImageView';
const ImageSlider = ({imgs}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);


// console.log(imgs)

  const images = [
    'https://picsum.photos/200/300',
    'https://picsum.photos/200/301',
    'https://picsum.photos/200/302',
    'https://picsum.photos/200/303',
  ];

  const previousImage = () => {
    setCurrentImageIndex((currentImageIndex + images.length - 1) % images.length);
  };

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };
  const goToImage = (i) => {
    setCurrentImageIndex(i % imgs.length)
  }
  return (
    <div className='product-item mx-auto'>
      {imgs[currentImageIndex].length >= 1 ? ( <ImageViewer className='mt-2 image-slider' src={"../client/public/imgupload/" + imgs[currentImageIndex]} alt="slider"/>) : (<>No images</>)}
      {/* {imgs[currentImageIndex].length >= 1 ? ( <ImageViewer className='mt-2 image-slider' src={"../build/imgupload/" + imgs[currentImageIndex]} alt="slider"/>) : (<>No images</>)} */}
     
      <div>
      {imgs.map((im , i) => (
        im.length >=1 ? (  <button className='slider-btn' onClick={() => goToImage(i)}>{i+1}</button>) :('')
      
      ))}
    </div>
    </div>
  );
};

export default ImageSlider;