import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal, Form, Col, Image, Alert } from "react-bootstrap";
import { TrashFill } from 'react-bootstrap-icons';

import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { LanguageContext } from '../context/LanguageContext';
import Lang from '../utils/Lang'
import Loading from './Loading';
export default function NewProd({ fresh }) {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const auth = useContext(AuthContext);
    const { authState } = auth;;
    const fetchContext = useContext(FetchContext);
    const [loadingMsg, setLoadingMsg] = useState('')
    const [showError, setShowError] = useState(false)
    const [errMsg, setErrMsg] = useState('')

    const [refresh, setRefresh] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [prodObject, setProdObject] = useState({
    });
    const { language } = useContext(LanguageContext);
    const [pictures, setPictures] = useState([])
    const [file, setFile] = useState([]);
    const [disableBtn, setDisableBtn] = useState(false)
    const [disableSub, setDisableSub] = useState(false)

    useEffect(() => {
        handleForm()
    }, [prodObject, pictures]);
    function handleInputChange(event) {

        const { name, value } = event.target;
        console.log(name + " " + value)
        if (event.target.name === 'files') {
            if (event.target.files.length !== 0) {
                // console.log("file")
                // console.log(event.target.files)
                setFile([...file, event.target.files[0]]);
                // console.log(URL.createObjectURL(event.target.files[0]))
                setPictures([...pictures, { blob: URL.createObjectURL(event.target.files[0]), filename: event.target.files[0].name }])

                // console.log(file.length)
                if (file.length >= 3) {
                    setDisableBtn(true)
                }


            }

        } else {
            setProdObject({ ...prodObject, [name]: value });

        }
        setShowError(false)
        handleForm()
    }
    function deletefile(e) {
        // console.log(e.target.name)
        console.log(e)
        const name = e

        let pics = pictures.filter(picture => picture.filename !== name)
        let fils = file.filter(fil => fil.name !== name)
        setPictures(pics)
        setFile(fils)

        setDisableBtn(false)

    }
    async function handleForm() {

        if (
            prodObject.FOB_Price?.length >= 1 &&
            prodObject.description?.length >= 1 &&
            prodObject.CM_Inch?.length >= 1 && (
                prodObject.ItemSizeLength?.length >= 1 ||
                prodObject.ItemSizeWidth?.length >= 1 ||
                prodObject.ItemSizeHeight?.length >= 1 ||
                prodObject.ItemSizeDiameter?.length >= 1) &&
          
            prodObject.packaging?.length >= 1 &&
            prodObject.supplierID?.length >= 1 &&
            file?.length >= 1
        ) {
            // console.log("able submit")
            setDisableSub(false)
        } else {
            // console.log("disbale submit")
            setDisableSub(true)
        }

    }

    async function handleSumbit(e) {
        e.preventDefault();
        let formData = new FormData();
        console.log('sub file')
        console.log(file)
        console.log(Object.keys(file))
        console.log(JSON.stringify(prodObject))
        for (const key of Object.keys(file)) {
            formData.append('file', file[key])
            console.log(file[key])
        }
        formData.append('prodInfo', JSON.stringify(prodObject))
        setIsLoading(true)
        setLoadingMsg('Updating Database')
        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        try {

            await fetchContext.fileAxios({
                method: "post",
                url: "/productnew",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((res) => {
                console.log(res.data)

                if (res.data.message === 'Session Expired') {
                    auth.logout()
                }
                if (res.data.message === '504') {
                    console.log("not unique")
                    setErrMsg("Supplier Item # must be unique.")
                    setShowError(true)
                    setIsLoading(false)
                } else if (res.data.message === '509') {
                    setErrMsg("Required field missing value.")
                    setShowError(true)
                    setIsLoading(false)
                } else {
                    setLoadingMsg("Success!")
                    setTimeout(function () {
                        // onSuccess()
                        handleClose()
                        setIsLoading(false)
                    }, 1500);
                    setProdObject({})
                    setPictures([])
                    setFile([]);
                    fresh(true);

                }



            });
        } catch (error) {
            console.log(error);
        }

    };



    return (
        <>
            <Button style={{ width: "85px" }} className='btn-red mb-2 float-end  btn-txt' onClick={handleShow}>
                {Lang.submit_new[`${language}`]}
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton className='py-1'>
                    <Modal.Title>{Lang.new_product_entry[`${language}`]}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-0'>
                    {isLoading ?
                        <>
                            <Loading />
                            <p className='text-center'>{loadingMsg}</p>
                        </>

                        : <>
                            <Form id="form" onSubmit={(e) => handleSumbit(e)} className="formTxt">

                                {showError ? (<Alert className="text-center" variant="danger">
                                    {errMsg}
                                </Alert>) : ('')}
                                <Form.Group className='d-flex' controlId="formDesc">
                                    <Form.Label>{Lang.product_description[`${language}`]}*</Form.Label>
                                    <Form.Control
                                        value={prodObject.description}
                                        as="textarea"
                                        rows={1}
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Product Description"
                                        name="description"
                                        className={prodObject.description?.length >= 1 ? "" : ' border border-danger'}
                                    />
                                </Form.Group>



                                <Form.Group as={Col} className='d-flex' controlId="formSupplyNum">
                                    <Form.Label className="lableTxt">{Lang.supplier_item[`${language}`]}*</Form.Label>
                                    <Form.Control
                                        autocomplete="off"
                                        required
                                        value={prodObject.supplierID}
                                        name="supplierID"
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Supplier Item #"
                                        className={prodObject.supplierID?.length >= 1 ? "" : ' border border-danger'}
                                    />
                                </Form.Group>

                                <Form.Group className='d-flex' as={Col} controlId="formFOB">
                                    <Form.Label className="lableTxt">{Lang.fob_price[`${language}`]}*</Form.Label>
                                    <Form.Control
                                        type="number"
                                        autocomplete="off"
                                        onChange={(e) => handleInputChange(e)}
                                        value={prodObject.FOB_Price}
                                        required
                                        placeholder="FOB Price"
                                        name="FOB_Price"
                                        className={prodObject.FOB_Price?.length >= 1 ? "" : ' border border-danger'}
                                    />
                                    <Form.Select onChange={(e) => handleInputChange(e)}
                                        value={prodObject.PriceType}
                                        className="form-control"
                                        style={{ width: "160px" }}

                                        name="PriceType">

                                        <option value="USD">USD </option>
                                        <option value="RMD">RMD</option>

                                    </Form.Select>


                                </Form.Group>

                                <Form.Group className='d-flex' as={Col} controlId="formGridIssue">
                                    <Form.Label className="lableTxt">{Lang.unit[`${language}`]}</Form.Label>

                                    <Form.Select onChange={(e) => handleInputChange(e)}

                                        className="form-control"
                                        style={{ width: "120px" }}
                                        autocomplete="off"
                                        required

                                        placeholder="Unit"
                                        name="unit"
                                        value={prodObject.unit} >

                                        <option value="pc">PC </option>
                                        <option value="pair">Pair</option>
                                        <option value="set">Set</option>

                                    </Form.Select>

                                </Form.Group>
                                <Form.Group className='d-flex' as={Col} controlId="formGridIssue">
                                    <Form.Label className="lableTxt">{Lang.Packaging[`${language}`]} *</Form.Label>
                                    <Form.Control
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Packaging"
                                        name="packaging"
                                        value={prodObject.packaging}
                                        className={prodObject.packaging?.length >= 1 ? "" : ' border border-danger'}
                                    />
                                </Form.Group>


                                <Form.Group className='d-flex' controlId="ControlTextarea1">
                                    <Form.Label className="lableTxt">{Lang.material[`${language}`]}</Form.Label>
                                    <Form.Control
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Material"
                                        type='text'
                                        name="material"
                                        value={prodObject.material}
                                    />
                                </Form.Group>
                                <Form.Group className='d-flex' as={Col} >
                                    <Form.Label className="lableTxt">{Lang.item_size[`${language}`]} *</Form.Label>
                                    <Form.Check
                                        inline
                                        label={Lang.Centimeter[`${language}`]}
                                        value="Centimeter"
                                        onChange={(e) => handleInputChange(e)}
                                        name="CM_Inch"
                                        checked={prodObject.CM_Inch === "Centimeter" ? (true) : false}
                                        className={prodObject.CM_Inch?.length >= 1 ? "" : ' border border-danger'}

                                    />
                                    <Form.Check
                                        inline
                                        label={Lang.Inch[`${language}`]}
                                        onChange={(e) => handleInputChange(e)}
                                        name="CM_Inch"
                                        value="Inch"
                                        checked={prodObject.CM_Inch === 'Inch' ? (true) : false}
                                        className={prodObject.CM_Inch?.length >= 1 ? "" : ' border border-danger'}
                                    />

                                </Form.Group>
                                <Form.Group>
                                    <div className='d-flex text-center dimBox mb-2'>
                                        <Form.Group as={Col} controlId='dem' className="dimTxt">
                                            <Form.Label className="dimTxt  pe-4" style={{ maxWidth: '15px' }}>{Lang.length[`${language}`]}</Form.Label>
                                            <Form.Control

                                                onChange={(e) => handleInputChange(e)}
                                                required
                                                type="text"
                                                placeholder="Length"
                                                name="ItemSizeLength"
                                                value={prodObject.ItemSizeLength}
                                                className={prodObject.ItemSizeLength?.length || prodObject.ItemSizeWidth?.length || prodObject.ItemSizeHeight?.length || prodObject.ItemSizeDiameter?.length >= 1 ? "text-center formInput dim" : 'text-center formInput dim border border-danger'}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId='dem' className="dimTxt" >
                                            <Form.Label className="lableTxt pe-4">{Lang.width[`${language}`]}</Form.Label>
                                            <Form.Control
                                                onChange={(e) => handleInputChange(e)}

                                                className={prodObject.ItemSizeLength?.length || prodObject.ItemSizeWidth?.length || prodObject.ItemSizeHeight?.length || prodObject.ItemSizeDiameter?.length >= 1 ? "text-center formInput dim" : 'text-center formInput dim border border-danger'}
                                                type="text"
                                                placeholder="Width"
                                                name="ItemSizeWidth"
                                                required
                                                size='sm'
                                                value={prodObject.ItemSizeWidth}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId='dem' className="dimTxt" >
                                            <Form.Label className="lableTxt pe-4">{Lang.height[`${language}`]}</Form.Label>
                                            <Form.Control
                                                onChange={(e) => handleInputChange(e)}

                                                className={prodObject.ItemSizeLength?.length || prodObject.ItemSizeWidth?.length || prodObject.ItemSizeHeight?.length || prodObject.ItemSizeDiameter?.length >= 1 ? "text-center formInput dim" : 'text-center formInput dim border border-danger'}

                                                type="text"
                                                placeholder="Height"
                                                name="ItemSizeHeight"

                                                value={prodObject.ItemSizeHeight}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId='dem' className="dimTxt">
                                            <Form.Label className="lableTxt dimTxt pe-4">{Lang.diameter[`${language}`]}</Form.Label>
                                            <Form.Control
                                                onChange={(e) => handleInputChange(e)}

                                                className={prodObject.ItemSizeLength?.length || prodObject.ItemSizeWidth?.length || prodObject.ItemSizeHeight?.length || prodObject.ItemSizeDiameter?.length >= 1 ? "text-center formInput dim" : 'text-center formInput dim border border-danger'}

                                                value={prodObject.ItemSizeDiameter}
                                                placeholder="Diameter"
                                                name="ItemSizeDiameter"

                                            />
                                        </Form.Group>
                                    </div>
                                </Form.Group>
                                <Form.Group className='d-flex' as={Col} controlId="formGridPassword">
                                    <Form.Label className="lableTxt">{Lang.fob_port[`${language}`]}</Form.Label>
                                    <Form.Select onChange={(e) => handleInputChange(e)}
                                        value={prodObject.FOB_Port}
                                        className="form-control"
                                        placeholder="FOB Port"
                                        name="FOB_Port">


                                        <option value="">Select </option>
                                        <option value="Ningbo">Ningbo</option>
                                        <option value="Xiamen">Xiamen</option>
                                        <option value="Qingdao">Qingdao</option>
                                        <option value="Yantian">Yantian</option>
                                        <option value="Shenchen">Shenchen</option>
                                        <option value="Domestic">Domestic</option>
                                        <option value="Shanghai">Shanghai</option>
                                    </Form.Select>


                                </Form.Group>
                                <Form.Group className='d-flex' as={Col} controlId="formGridPassword">
                                    <Form.Label className="lableTxt">{Lang.moq[`${language}`]}</Form.Label>
                                    <Form.Control
                                        onChange={(e) => handleInputChange(e)}
                                        value={prodObject.MOQ}

                                        placeholder="MOQ"
                                        name="MOQ"
                                    />

                                </Form.Group>
                                <Form.Group className='d-flex' as={Col} controlId="formGridPassword">
                                    <Form.Label className="lableTxt">{Lang.production_time[`${language}`]}</Form.Label>
                                    <Form.Control
                                        onChange={(e) => handleInputChange(e)}
                                        value={prodObject.ProductionTime}

                                        placeholder="Production Time"
                                        name="ProductionTime"
                                    />

                                </Form.Group>

                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <Form.Label className="lableTxt">{Lang.product_image[`${language}`]} *</Form.Label>
                                    <Form.Text className="text-muted d-flex">
                                        <text>{Lang.select_imgs[`${language}`]}</text>
                                    </Form.Text>
                                    <Form.Control name="files"
                                        className={pictures?.length >= 1 ? "" : '  border border-danger'}

                                        type="file" multiple="multiple" disabled={disableBtn} onChange={(e) => handleInputChange(e)} />
                                    <Form.Text className="text-muted">

                                        <text> Only use images &lt; 1 mb in size (.jpg, .jpeg, .gif, .png, .tif, .tiff)</text>
                                    </Form.Text>
                                </Form.Group>

                                {
                                    pictures.length ? (pictures.map((file) => (

                                        <div style={{ display: 'inline-block', position: 'relative' }}>
                                            <Image className="img-fluid mx-1" src={file.blob} alt="Card image cap" style={{ maxWidth: '75px' }} />
                                            <Button
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    fontSize: '12px', lineHeight: "1", padding: '2.5px 3px'
                                                }}
                                                variant="danger"
                                                onClick={() => deletefile(file.filename)}
                                            >
                                                <TrashFill onClick={() => deletefile(file.filename)} />
                                            </Button>
                                        </div>
                                        // <div style={{ position: 'relative',   flexDirection:"column" }}>

                                        //     <Button name={file.filename}
                                        //         style={{
                                        //             zIndex: '1', marginBottom: '8%', fontSize: '12px', lineHeight: "1", padding: '2.5px 3px', position: 'absolute',
                                        //             top: 0,
                                        //             right: 0,
                                        //         }}
                                        //         variant="danger">
                                        //         <TrashFill onClick={() => deletefile(file.filename)} />
                                        //     </Button>
                                        //     <Image className="img-fluid" src={file.blob} alt="Card image cap" style={{ maxWidth: '75px' }} />


                                        // </div>


                                    ))) : ("")
                                }
                                <Form.Group className='' controlId="formDesc">
                                    <Form.Label>{Lang.notes[`${language}`]} *</Form.Label>
                                    <Form.Control

                                        as="textarea"
                                        rows={2}
                                      
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Notes"
                                        name="notes"
                                        className=' '

                                    />
                                </Form.Group>
                            </Form>
                            <text>* <strong>Required field</strong> <br />
                                {Lang.astrick[`${language}`]}</text>
                        </>}
                </Modal.Body>

                <Modal.Footer className='justify-content-between'>
                    {isLoading ? '' : <>                 <Button className='btn-red mb-2   btn-txt' onClick={handleClose}>
                        Cancel
                    </Button>
                        <Button disabled={disableSub} className='btn-red mb-2 float-end btn-hover  btn-txt' onClick={(e) => handleSumbit(e)}>
                            {Lang.submit_new[`${language}`]}
                        </Button></>}

                </Modal.Footer>
            </Modal>
        </>
    );
}
