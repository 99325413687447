import React, { useEffect, useState, useContext } from "react";
import { Container, Row } from "react-bootstrap";
 
export default function Home() {
 


    return (
        <Container className="justify-content-center text-center">
            <p>Penguin Patch is not compatible with Firefox. Please try using one of the browsers listed below.</p>
            <ul>
                <li>Chrome</li>
                <li>Safari</li>
                <li>Microsoft Edge</li>
            </ul>
        </Container>
    )
}