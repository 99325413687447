import React, { useEffect, useState, useContext } from "react";
import { Container, Row } from "react-bootstrap";
import ProductOver from "../components/Product";
import NewProd from "../components/NewProduct";
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
export default function Home() {
    const auth = useContext(AuthContext);
    const { authState } = auth;;
    const fetchContext = useContext(FetchContext);
    const [fobOption, setFobOption] = useState([])
 


    return (
        <Container className="justify-content-center text-center">
            
            <Row className="justify-content-center"> <ProductOver fobOption={fobOption} /></Row>
        </Container>
    )
}