import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Form, Col, Image, Row } from "react-bootstrap";
import { TrashFill } from 'react-bootstrap-icons';
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { LanguageContext } from '../context/LanguageContext';
import Lang from '../utils/Lang'
import Loading from './Loading';
export default function EditImage({ prData, onSuccess, refreshDetail }) {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const auth = useContext(AuthContext);
    const { authState } = auth;;
    const fetchContext = useContext(FetchContext);
    const [loadingMsg, setLoadingMsg] = useState('')
    const [doSome, setDoSome] = useState(true)
    const [refresh, setRefresh] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [prodObject, setProdObject] = useState({
        description: prData["Description(1)"],
        supplierID: prData["Supplier Item Num(1)"],
        FOB_Price: prData.FOB_Price,
        unit: prData.Unit,
        packaging: prData["Packaging(1)"],
        material: prData.Material,
        CM_Inch: prData.CM_Inch,
        ItemSizeLength: prData.ItemSizeLength,
        ItemSizeWidth: prData.ItemSizeWidth,
        ItemSizeHeight: prData.ItemSizeHeight,
        ItemSizeDiameter: prData.ItemSizeDiameter,
        ProductionTime: prData.ProductionTime,
        recordID: prData.recordID,
        ImageName1: prData.ImageName1,
        ImageName2: prData.ImageName2,
        ImageName3: prData.ImageName3,
        ImageName4: prData.ImageName4,
    });
    const [pictures, setPictures] = useState([])
    //     const [pictures, setPictures] = useState(
    //         {"blob": prData.ImageName1, "filename": prData.ImageName1}  ,
    //         {"blob": prData.ImageName2, "filename": prData.ImageName2}  ,
    //         {"blob": prData.ImageName3, "filename": prData.ImageName3}  ,
    //         {"blob": prData.ImageName4, "filename": prData.ImageName4}  

    // )

    // const [pictures, setPictures] = useState([prData.ImageName1,
    // prData.ImageName2,
    // prData.ImageName3,
    // prData.ImageName4])
    const [file, setFile] = useState([]);
    const [disableBtn, setDisableBtn] = useState(false)
    const { language } = useContext(LanguageContext);
    const [picDelete, setPicDelete] = useState([])
    useEffect(() => {
        // console.log(doSome)
        if (doSome) {
            setPics()

        }
        // console.log(prodObject)
        setRefresh(true)
        // console.log(prData)
        // console.log(pictures)
    }, [prData, prodObject, pictures, refresh]);

    function setPics() {
        // console.log(prData)
        setPictures([...pictures,
        { "blob": prData.ImageName1, "filename": prData.ImageName1, "fieldName": "ImageName1", file: { "none": '' } },
        { "blob": prData.ImageName2, "filename": prData.ImageName2, "fieldName": "ImageName2", file: { "none": '' } },
        { "blob": prData.ImageName3, "filename": prData.ImageName3, "fieldName": "ImageName3", file: { "none": '' } },
        { "blob": prData.ImageName4, "filename": prData.ImageName4, "fieldName": "ImageName4", file: { "none": '' } }])


        setDoSome(false)
    }
    function handleInputChange(event, i) {

        const { name, value } = event.target;
        console.log(name, value)
        console.log(i)
        if (event.target.name === 'files') {
            if (event.target.files.length !== 0) {
                // console.log("file")
                // console.log(event.target.files)
                let letter;
                if (i === 0) { letter = 'A' }
                if (i === 1) { letter = 'B' }
                if (i === 2) { letter = 'C' }
                if (i === 3) { letter = 'D' }

                let fileNew = event.target.files[0];
                // console.log(fileNew.name)
                // Get the current file name
                let currentFileName = fileNew.name;
                var str = currentFileName;
                var n = currentFileName.lastIndexOf('.');
                var ext = str.substring(n + 1);
                // Update the file name
                let newFileName = (prData.recordID + letter + "." + ext).toString();
                // console.log(newFileName)

                let updatedFile = new File([fileNew], newFileName, { type: file.type });

                setFile([...file, updatedFile]);
                // console.log(URL.createObjectURL(event.target.files[0]))


                pictures[i].blob = URL.createObjectURL(event.target.files[0]);
                pictures[i].filename = newFileName;
                pictures[i].fieldName = "ImageName" + (i + 1);
                pictures[i].file = event.target.files

                // console.log(pictures)
                // setPictures([...pictures, { blob: URL.createObjectURL(event.target.files[0]), filename: event.target.files[0].name }])
                setPictures(pictures, setRefresh(false))


                // if (pictures.length >= 3) {
                //     setDisableBtn(true)
                // }


            }

        }

    }
    function deletefile(pic, i) {
        // console.log(i)
        // console.log(pic)
        setPicDelete([...picDelete, { "fileName": pic.filename, "fieldName": pic.fieldName }])
        // console.log(picDelete)
        let fils = file.filter(fil => fil.name !== pic.filename)
        pictures[i].blob = '';
        pictures[i].filename = '';
        pictures[i].fieldName = "ImageName" + (i + 1);;
        pictures[i].file = ''

        // console.log(fils)
        setFile(fils)
        // console.log(pictures)
        setRefresh(false)



    }

    async function handleSumbit(e) {
        e.preventDefault();

        let formData = new FormData();
        // console.log('sub file')
        // console.log(pictures)

        // console.log(JSON.stringify(pictures))

        for (const key of Object.keys(file)) {

            formData.append('file', file[key])

            // console.log(file[key])
        }
        formData.append('recID', prData.recordID)
        formData.append('picDelete', JSON.stringify(picDelete))
        formData.append('imageupdate', JSON.stringify(pictures))
        setIsLoading(true)
        setLoadingMsg('Updating Database')
        for (var pair of formData.entries()) {
            // console.log(pair[0] + ', ' + pair[1]);
        }
        try {
            await fetchContext.authAxios.post("/deleteimgs", picDelete).then(async delres => {
                console.log(delres)
                await fetchContext.fileAxios({
                    method: "post",
                    url: "/imageupdate",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then((res) => {
                    console.log(res.data)

                    if (res.data.message === 'Session Expired') {
                        auth.logout()
                    }

                    setLoadingMsg("Success!")
                    setTimeout(function () {
                        // onSuccess()
                        if (onSuccess) {
                            onSuccess()
                        } else {
                          refreshDetail()
                        }
                        handleClose()
                        setIsLoading(false)

                    }, 1500);
                    setProdObject({})
                    // console.log("window reload")
                    // window.location.reload("?t=" + new Date().getTime())



                });
            })


        } catch (error) {
            console.log(error);
        }
    };

    function selectFile() {
        console.log("btn click")
        document.getElementById('selectedFile').click()

    }
    return (
        <>
            <Button className='btn-red mb-2 mx-1 float-end  btn-txt' onClick={handleShow}>
                {Lang.edit_image[`${language}`]}
            </Button>
            <Modal show={show} onHide={handleClose} backdrop="static" dialogClassName="adjmodal">
                <Modal.Header closeButton>
                    <Modal.Title>  {Lang.edit_image[`${language}`]} - {prodObject.description}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ?
                        <>
                            <Loading />
                            <p className='text-center'>{loadingMsg}</p>
                        </>

                        : <>
                            <Form onSubmit={(e) => handleSumbit(e)} className="formTxt">
                                <div className='text-center align-items-between'>

                                    {pictures.length >= 1 ? (
                                        <div className='d-flex '>
                                            {pictures.map((pic, i) => (

                                                <Col className='mx-3 border p-2 '>
                                                    <Row className='justify-content-center'>
                                                        <div style={{ minWidth: '180px' }}>
                                                            {pic.blob.length >= 1 ? (<>
                                                                <Image className="img-fluid mx-1 mb-5"
                                                                    src={pic.blob.includes("blob") ? pic.blob : "../client/public/imgupload/" + pic.blob}
                                                                    alt="Card image cap"
                                                                    style={{ maxWidth: '165px' }} />
                                                                <Button
                                                                    style={{
                                                                        width: '25px',
                                                                        position: 'absolute',
                                                                        marginLeft: '-20px',

                                                                        fontSize: '12px', lineHeight: "1", padding: '2.5px 3px'
                                                                    }}
                                                                    variant="danger"
                                                                    className='trashcan'
                                                                    onClick={() => deletefile(pic, i)}
                                                                >
                                                                    <TrashFill />
                                                                </Button> </>) : ''}
                                                        </div>

                                                    </Row>
                                                    <Row className='  fileimg'>
                                                        <Form.Group controlId="formFileMultiple" className="mb-3 ">


                                                            <Form.Control name="files" type="file" disabled={disableBtn} onChange={(e) => handleInputChange(e, i)} />

                                                        </Form.Group>
                                                    </Row>
                                                </Col>


                                            ))
                                            }

                                        </div>
                                    ) : ('')}






                                </div>


                                <Form.Text className="text-muted">

                                    <text> Only use images &lt; 1 mb in size (.jpg, .jpeg, .gif, .png, .tif, .tiff)</text>
                                </Form.Text>

                            </Form>
                        </>}
                </Modal.Body>

                <Modal.Footer className='justify-content-between'>
                    {isLoading ? '' : <>                 <Button className='btn-red mb-2   btn-txt' onClick={handleClose}>
                        Cancel
                    </Button>
                        <Button className='btn-red mb-2 float-end btn-hover  btn-txt' onClick={(e) => handleSumbit(e)}>
                            {Lang.submit_new_changes[`${language}`]}
                        </Button></>}

                </Modal.Footer>
            </Modal>
        </>
    );
}
