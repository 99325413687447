import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Row, Col, Button, Form, InputGroup, Image, Spinner, Container } from "react-bootstrap";

import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import Loading from "../components/Loading";

export default function Login() {
  const authContext = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);
  const { authState } = useContext(AuthContext);
  const [loading, setIsLoading] = useState(false)
  const history = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [authText, setAuthText] = useState(false)
  const [redirectOnLogin, setRedirectOnLogin] = useState(authState.isAuthenticated);
  const [formObject, setFormObject] = useState({
    username: "",
    password: "",
  });
  function handleInputChange(event) {

    const { name, value } = event.target;
    setAuthText(false);

    setFormObject({ ...formObject, [name]: value });
  }

  useEffect(() => {
    checkForm();
    
  }, [checkForm, formObject, redirectOnLogin]);


  const [formFull, setFormFull] = useState(true);

  function checkAuth() {
 

    if (authState.userInfo === undefined) {
      history("/login")
    } else {
      setRedirectOnLogin(true)
      history("/products")
    }
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  function checkForm() {


    if (
      formObject.username.length >= 2 &&
      formObject.password.length > 5

    ) {
      setFormFull(true);
    } else {
      setFormFull(false);
    }
  }
  async function handleSubmit(e) {
    setIsLoading(true)
    e.preventDefault();
    e.stopPropagation();
    try {
      const { data } = await fetchContext.publicAxios.post("login", formObject);
      console.log(data)
      if (data.message === "Invalid user account or password") {
        setIsLoading(false)
        setAuthText(true);
      } else {
        authContext.setAuthState(data);
        // window.location.reload(false);


        setRedirectOnLogin(true);
        setIsLoading(false)
        history("/products")

      }
    } catch (error) {
      console.log(error.message);
    }
  }
  function passview(e) {

    if (showPass) {

      setShowPass(false)
      document.getElementById(e).type = "password"
    } else {
      setShowPass(true)
      document.getElementById(e).type = "text"
    }
  }

  return (

    <>
      {redirectOnLogin && <Navigate to="/products" />}
      <Row

      >

        <div className="Auth-form-container">
          <form className="Auth-form"
            onSubmit={handleSubmit}
          >
            <div className="Auth-form-content text-center">

              <h3 className="Auth-form-title">Sign In</h3>
              {authText ? (<Form.Text className="text-danger">
                Invalid user account or password
              </Form.Text>) : ("")}
              <div className="form-group mt-3">
                <label>Username</label>
                <input
                  type="username"
                  name="username"
                  className="form-control mt-1"
                  placeholder="Enter username"
                  onChange={(e) => handleInputChange(e)}
                />

              </div>
              <div className="form-group mt-3">
                <label>Password</label>
                <InputGroup className="">
                  <Form.Control
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter Password"
                    aria-label="Enter Password"
                    onChange={(e) => handleInputChange(e)}
                    aria-describedby="basic-addon2"
                  />
                  <Button variant="outline-secondary" id="button-addon2" onClick={(e) => passview("password")}>
                    {showPass ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                  </Button>
                </InputGroup>

              </div>
              <div className="d-grid gap-2 mt-3">
             
                <Button type="submit" className="btn-red mb-2 btn-txt mx-1"   disabled={formFull ? false : true}>
                  <>                  {loading ? (<>

                 
                    {/* <Spinner  size="sm" animation="border" variant="light" /> */}

                    <Loading   />
                  </>) : ('Submit')}
                  </>

                </Button>
              </div>
              <p className="forgot-password text-right mt-2">
                <a href="/forgotpassword">Forgot password?</a>
              </p>
            </div>
          </form>
        </div>

      </Row>
    </>
  );
}
