import React, { useState } from 'react';


function LanguageProvider(props) {
    const [language, setLanguage] = useState('en'); // Set the initial language to English
  
    const changeLanguage = (newLanguage) => {
      setLanguage(newLanguage);
    }
  console.log("language context")
  console.log(language)
    return (
      <LanguageContext.Provider value={{ language, changeLanguage }}>
        {props.children}
      </LanguageContext.Provider>
    );
  }
  
  export default LanguageProvider;

export const LanguageContext = React.createContext();