import React, { useState } from 'react';
import { Button, Modal, Image } from 'react-bootstrap';


export default function ImageViewer(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    return (<>
        <Image
            src={props.src}
            style={props.style}
            className={props.className}
            onClick={handleShow} />

        <Modal show={show} onHide={handleClose}  >

            <Modal.Body className='mx-auto'>

                <Image style={{ maxWidth: '400px' }} src={props.src} />

            </Modal.Body>

            <div className='text-center mb-2'>
                <Button className='btn-red mb-2  btn-txt' onClick={handleClose}>
                    Close
                </Button>

            </div>
        </Modal>
    </>);
}
