



export   const lang = {
    'back_to_item_detail':{'en' : 'Back to Item Detail', 'cn' : '返回到产品详情' },
    'back_to_list': {'en' : 'Back to List', 'cn': '返回列表'},
    'browse': {'en' : 'Browse', 'cn': '浏览'},
    'Centimeter': {'en' : 'Centimeters ', 'cn': '厘米'},
    'diameter': {'en' : 'Diameter', 'cn': '直径'},
    'edit_image': {'en' : 'Edit Image', 'cn': '编辑图片'},
    'edit_item': {'en' : 'Edit Item', 'cn': '编辑产品'},
    'edit_product': {'en' : 'Edit Product', 'cn': '产品编辑'},
    'edit_product_view': {'en' : 'Edit Product View', 'cn': '编辑产品图片'},
    'fob_price': {'en' : 'FOB Price', 'cn': 'FOB 价格'},
    'height': {'en' : 'Height', 'cn': '高度'},
    'Inch': {'en' : 'Inches', 'cn': '英寸'},
    'item_size': {'en' : 'Item Size', 'cn': '产品尺寸'},
    'length': {'en' : 'Length', 'cn': '长度'},
    'material': {'en' : 'Material', 'cn': '材料'},
    'moq': {'en' : 'MOQ', 'cn': '最少起订量'},
    'new_product_entry': {'en' : 'New Product View', 'cn': '新产品条目'},
    'product_description': {'en' : 'Product Description', 'cn': '产品描述'},
    'product_image': {'en' : 'Product Image', 'cn': '产品图片'},
    'production_time': {'en' : 'Production Time', 'cn': '生产时间'},
    'remove_image': {'en' : 'Remove image', 'cn': '移除图片'},
    'submit_new': {'en' : 'Submit New ', 'cn': '提交产品'},
    'submit_new_changes': {'en' : 'Save Changes', 'cn': '提交最新资料'},
    'submit_product': {'en' : 'Submit Product', 'cn': '提交产品'},
  
    'unit': {'en' : 'Unit', 'cn': '单位'},
    'update_file': {'en' : 'Update File', 'cn': '更新图片'},
    'view_details': {'en' : 'View Details', 'cn': '预览详情'},
    'width': {'en' : 'Width', 'cn': '宽度'},
    'username': {'en' : 'Username', 'cn': '用户名'},
    'password': {'en' : 'Password', 'cn': '密码'},
    'supplier_name': {'en' : 'Supplier Name', 'cn': '供应商'},
    'supplier_item' : {'en' : 'Supplier Item #', 'cn' : '供应商物料编号'},
    'description': {'en' : 'Description', 'cn': '描述'},
    'date_submitted': {'en' : 'Date Submitted', 'cn': '提交日期'},
    'fob_port': {'en' : 'FOB Port', 'cn': '装运港口岸'},
    'required_field': {'en' : 'Required field', 'cn': '必填'},
    'add_note': {'en' : 'Add Note', 'cn': '备注'},
    'topic': {'en' : 'Topic', 'cn': '主题'},
    'notes': {'en' : 'Notes', 'cn': '笔记'},
    'submit_note': {'en' : 'Submit Note', 'cn': '提交产品'},
    'select_imgs': {'en' : 'Select up to 4 images', 'cn': '最多选择 4 张图像'},
    'Packaging': {'en' : 'Packaging' , 'cn': '包装}'},
    'product_search': {'en' : 'Product Search' , 'cn': '产品搜索'},
    'astrick': {'en' : ' All text entries should be in English even when viewing with Chinese labels.' , 'cn': '即使使用中文标签查看，所有文本条目也应为英文。'},
}

 




export default  lang
