import React, { useContext, useState } from "react";
import Nav from "./components/Nav";
import { Row, Spinner } from "react-bootstrap";
import { AuthContext } from "./context/authContext";
import LanguageProvider from './context/LanguageContext';
const LoadingLogo = () => {
  return (
    <Row className="justify-content-center">
      <Spinner animation="grow" variant="danger" />
    </Row>
  );
};
const AppShell = ({ children }) => {

  const { authState } = useContext(AuthContext);

  if (!authState.userInfo) {
    return (
      <div className="text-center">
        <LoadingLogo />
      </div>
    );
  }
  function langClick(e) {
    console.log(e.target.id)
    let id = e.target.id
    let ln = document.getElementById(id)
    var lights = document.getElementsByClassName("btn-border");
    while (lights.length)
      lights[0].className = lights[0].className.replace("btn-border", "");
    ln.classList.add("btn-border");
  }

  return (
    <>
      <LanguageProvider>
        <Nav langClick={(e) => langClick(e)} />
        <div className="app" >{children}</div>
      </LanguageProvider>
    </>
  );
};

export default AppShell;