import React, { useContext } from "react";
import AppShell from "./AppShell";
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { AuthProvider, AuthContext } from "./context/authContext";
import { FetchProvider } from "./context/FetchContext";

import Home from './pages/Home'
import Login from './pages/Login'
import Details from "./pages/Details";
import Browser from "./pages/Browser";


function RequireAuth({ children }) {
  const { authState } = useContext(AuthContext);
  return authState.isAuthenticated === true ? children : <Navigate to="/" />;
};

function CheckBrowser() {
  if (window.location.href !== "https://ppatch.alamark.com/browsersupport") {
    if (navigator.userAgent.indexOf("Firefox") !== -1) {
      // Redirect to a new URL
      window.location.href = "https://ppatch.alamark.com/browsersupport";
    }
  }
}
CheckBrowser()

function App() {
  return (
    <Router>

      <FetchProvider>
        <AuthProvider>
          <AppShell>
            <div>
        
                <Routes>
                  <Route path="/browsersupport" element={<Browser />} />
                  <Route
                    path="/products/*"
                    element={
                      <RequireAuth>
                        <Home />
                      </RequireAuth>} />
                  <Route exact path="/" element={<Login />} />

                  <Route

                    path="/details/*"
                    element={
                      <RequireAuth>
                        <Details />
                      </RequireAuth>} />
                </Routes>
        
            </div>
          </AppShell>
        </AuthProvider>
      </FetchProvider>
    </Router>
  );
}


export default App;
