import React, { useContext } from "react";
import Logo from "../../src/pics/ppatch-logo.png";
import Eng from "../../src/pics/icons8-usa-80.png"
import Cn from "../../src/pics/icons8-china-96.png"
import { Image, Row, Container, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from "../context/authContext";
import { LanguageContext } from '../context/LanguageContext';
import { useNavigate, useLocation } from "react-router-dom";

const AlamarkNav = () => {
    const history = useNavigate();
    const loc = useLocation()
    const auth = useContext(AuthContext);
    const { authState } = auth;
    const { language, changeLanguage } = useContext(LanguageContext);
    function goToLink(link) {
        console.log(link)
        history(`/${link}`)
    }

    function langClick(e) {
        console.log(e.target.id)
        let id = e.target.id
      
        let ln = document.getElementById(id)
        var lights = document.getElementsByClassName("btn-border");
        while (lights.length)
            lights[0].className = lights[0].className.replace("btn-border", "");
        ln.classList.add("btn-border");
    }
    function goHome() {
        history(`/home`)
    }

    return (
        <>
            <header>
                <Container>

                    <Row className="justify-content-end mx-5 mt-3 d-flex">
                        {authState.isAuthenticated ? (< > logged in as {authState.userInfo.userName.toUpperCase()}
                            <Button style={{ width: "70px" }} className='btn-red mb-2 float-end  btn-txt mx-3' onClick={auth.logout}>Log Out</Button> </>) : ('')}

                        <button id="eng" className="lang-button eng-btn mr-5" style={{ maxWidth: "45px" }} onClick={(e) => {changeLanguage('en'); langClick(e)}}>
                            {/* <Image src={Eng} style={{ maxWidth: "35px" }} /> */}
                        </button>
                        <button id="cn" className="lang-button cn-btn ms-3" style={{ maxWidth: "45px" }} onClick={(e) => {changeLanguage('cn'); ; langClick(e)}}>
                            {/* <Image src={Cn} style={{ maxWidth: "40px" }} /> */}
                        </button>

                    </Row>
                    <Row className="justify-content-center">
                        <div id="logo"><a href="/" title="Home">Jenny's Penguin Patch</a></div> 
                    </Row>
                </Container>
            </header>
        </>
    );
}

export default AlamarkNav;
