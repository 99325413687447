import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Image, Container, Row, Table } from "react-bootstrap";

import EditProd from '../components/EditProduct';
import ImageViewer from '../components/ImageView';
import NewProd from '../components/NewProduct';
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { LanguageContext } from '../context/LanguageContext';
import Lang from '../utils/Lang'
import EditImage from '../components/EditImage';

import AddNote from '../components/AddNote'
export default function Details({ prData }) {
    const { language } = useContext(LanguageContext);
    const nav = useNavigate();
    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const { authState } = auth;;
    const fetchContext = useContext(FetchContext);
    const { state } = useLocation();
    const [refresh, setRefresh] = useState(true);
    console.log(state)
    const [imgSrc, setImgSrc] = useState(state.fieldData.ImageName1.length >= 1 ? state.fieldData.ImageName1 : '' )
    console.log(prData)
    const [prodObject, setProdObject] = useState(state.fieldData);
    const [notes, setNotes] = useState(state.portalData["Web_Notes_||__id"])
const [imgs, setImgs] = useState([])

     



    useEffect(() => {
        setImages()
    refreshDetail()
    }, [  refresh]);

function setImages(){
   setImgs( [
        state.fieldData.ImageName1,
        state.fieldData.ImageName2,
        state.fieldData.ImageName3,
        state.fieldData.ImageName4])
}
    console.log(imgs)


    async function refreshDetail() {
        console.log("refresh detail")
        // console.log("window reload")
        // window.location.reload("?t=" + new Date().getTime())

        console.log(prodObject)
        try {
            await fetchContext.authAxios.post("/detail", prodObject).then((res) => {
                console.log(res.data)
                if (res.data.message === 'Session Expired') {
                    auth.logout()
                }
                if (res.data === 'no records') {

                    setIsLoading(false)
                } else {
                    console.log(res.data[0].fieldData)
                    setProdObject(res.data[0].fieldData);
                    setImgs([

                        res.data[0].fieldData.ImageName1,
                        res.data[0].fieldData.ImageName2,
                        res.data[0].fieldData.ImageName3,
                        res.data[0].fieldData.ImageName4
                    ])
                    setImgSrc( res.data[0].fieldData.ImageName1)
                    setNotes(res.data[0].portalData["Web_Notes_||__id"])
           
                    setRefresh(false);
                    setIsLoading(false)
                }

            });
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <Container >
                <Row>
                    <Col className='ps-4' md={7}>{prodObject['Description(1)'] + " - " + prodObject["Supplier Item Num(1)"]}</Col>
                    <Col md={5} className='float-end'>
                        <Button className='btn-red mb-2 float-end  btn-txt mx-1' onClick={() => nav('/products')} > {Lang.back_to_list[`${language}`]}</Button>
                        <NewProd />
                        <EditImage prData={prodObject} refreshDetail={() => refreshDetail()} />
                        <EditProd prData={prodObject} onSuccess={false} refreshDetail={() => refreshDetail()} />

                    </Col>
                </Row>
                <Row className='mx-auto'>
                    <Container className=''></Container>
                    <Col md={7} className="d-block text-start float-start">
                        <Table className="product-table border">
                            <tbody>
                                <tr>
                                    <td className="labelTxt"> {Lang.fob_price[`${language}`]}</td>
                                    <td>{parseFloat(prodObject.FOB_Price).toFixed(3) + " " + prodObject.PriceType}</td>
                                </tr>
                                <tr>
                                    <td className="labelTxt"> {Lang.unit[`${language}`]}</td>
                                    <td>{prodObject.Unit}</td>
                                </tr>
                                <tr>
                                    <td className="labelTxt"> {Lang.Packaging[`${language}`]}</td>
                                    <td>{prodObject['Packaging(1)']}</td>
                                </tr>
                                <tr>
                                    <td className="labelTxt"> {Lang.material[`${language}`]}</td>
                                    <td>{prodObject.Material}</td>
                                </tr>
                                <tr>
                                    <td className="labelTxt"> {Lang.item_size[`${language}`]}</td>
                                    <td>{prodObject.CM_Inch}


                                        <table className="dim-table">
                                            <tbody>
                                                <tr><td><span className="labelTxt ps-1 first"> {Lang.length[`${language}`]}</span> {prodObject.ItemSizeLength}</td>
                                                    <td><span className="labelTxt ps-1"> {Lang.width[`${language}`]}</span> {prodObject.ItemSizeWidth}</td>
                                                    <td><span className="labelTxt ps-1"> {Lang.height[`${language}`]}</span> {prodObject.ItemSizeHeight}</td>
                                                    <td><span className="labelTxt ps-1"> {Lang.diameter[`${language}`]}</span> {prodObject.ItemSizeDiameter}</td></tr>                                            </tbody>
                                        </table>


                                    </td>
                                </tr>
                                <tr>
                                    <td className="labelTxt"> {Lang.fob_port[`${language}`]}</td>
                                    <td>{prodObject.FOB_Port}</td>
                                </tr>
                                <tr>
                                    <td className="labelTxt"> {Lang.moq[`${language}`]}</td>
                                    <td>{prodObject.MOQ}</td>
                                </tr>
                                <tr>
                                    <td className="labelTxt"> {Lang.production_time[`${language}`]}</td>
                                    <td className="content1">{prodObject.ProductionTime}</td>
                                </tr>
                                <tr>
                                    <td className="labelTxt"> {Lang.date_submitted[`${language}`]}</td>
                                    <td>{prodObject.DateSubmitted}</td>
                                </tr>
                                <tr>
                                    <td className="labelTxt"> {Lang.supplier_name[`${language}`]}</td>
                                    <td className="content1">{prodObject['Supplier Name']}</td>
                                </tr>




                            </tbody>
                        </Table>
                        <div className='d-flex'>
                            <AddNote prData={prodObject} onSuccess={true} refreshDetail={() => refreshDetail()} />

                        </div>
                        {notes.length >= 1 ? (

                            notes.map((nt, i) => (

                                <div className='mb-2'>
                                    <div className='note-bg d-flex'>
                                        <Col><p className=' note-info d-flex'> {nt["Web_Notes_||__id::Author"]}</p></Col>
                                        <Col> <p className=' note-info text-center'>Topic: {nt["Web_Notes_||__id::Topic"]}</p></Col>
                                        <Col> {i === 0 ? (<p className=' note-info d-flex float-end pe-3 notes-text' >New</p>) : ''}</Col>
                                    </div>
                                    <div className=' border-notes'>
                                        <p>{nt["Web_Notes_||__id::Notes"]}</p>
                                    </div>

                                </div>
                            )
                            )
                        )
                            : ('')}


                    </Col>
                    <Col md={5}>

                        <Row className='d-flex justify-content-between mb-2'  >
                            <div className='d-flex justify-content-center align-items-center'>
                                {imgs.length >= 1 ? (
                                    imgs.map((im, i) => (
                                        <>

                                            {im.length >= 1 ? <Image src={"../client/public/imgupload/" + im} style={{ maxWidth: "100px" }} className={imgSrc === im ? ("mx-1 border border-danger") : ("mx-1")} onClick={() => setImgSrc(im)} /> : ('')}


                                        </>)
                                    )
                                ) : ('sdfsdf')}
                            </div>



                        </Row>
                        <Row className='' >
                            {imgSrc.length >= 1 ?  <ImageViewer src={"../client/public/imgupload/" + imgSrc} style={{ maxWidth: '100%' }} className="mx-auto border" /> : ""}
                          
                        </Row>



                    </Col>
                </Row>

            </Container>
        </>

    )
}