import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FetchContext } from "./FetchContext";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useNavigate();
  const fetchContext = useContext(FetchContext);

  const [authState, setAuthState] = useState({
    userInfo: null,
    isAuthenticated: null,
  });

  useEffect(() => {
    const getUserInfo = async () => {
      try {

        const { data } = await fetchContext.authAxios.get("/user");
        // console.log("/user")
        // console.log(data)
        // login(data)
        setAuthState({
          userInfo: data,
          isAuthenticated: true,
        });
      } catch (err) {
        // console.log(err.response.data)
        // console.log("setting auth false")
        setAuthState({
          userInfo: {},
          isAuthenticated: false,
        });
      }
    };

    getUserInfo();
  }, [fetchContext]);

  const setAuthInfo = ({ userInfo }) => {
    // console.log("Set Auth Info")
    // console.log(userInfo)
    // console.log(userInfo ? true : false )
    setAuthState({
      userInfo,
      isAuthenticated: userInfo ? true : false,
    });
  };

  // const login = async (data) => {
  //   // console.log("logout");
  //   try {
      

  //     setAuthState({
  //       userInfo: data,
  //       isAuthenticated: true,
  //     });
  //     console.log(data)
  //     if(data.firstLogin === 1){

  //       history("/resetpassword");
  //     }
  //     history("/dashboard");
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const logout = async () => {
    // console.log("logout");
    try {
      await fetchContext.publicAxios.post("/logout");

      setAuthState({
        userInfo: {},
        isAuthenticated: false,
      });
      history("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        logout, 
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
