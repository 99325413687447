import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Table, Button, Spinner } from "react-bootstrap";
import EditProd from "./EditProduct";
import ImageSlider from "./ImageSlider"
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { LanguageContext } from '../context/LanguageContext';
import Lang from '../utils/Lang'
import { useNavigate, useLocation } from "react-router-dom";
import EditImage from "./EditImage";
import NewProd from "./NewProduct";
 
export default function ProductOver() {
    const [isLoading, setIsLoading] = useState(true);
    const auth = useContext(AuthContext);
    const { authState } = auth;;
    const fetchContext = useContext(FetchContext);
    const [filterObject, setFilterObject] = useState({});
    const [noProd, setNoProd] = useState(false)
    const [refresh, setRefresh] = useState(true);
    const [prodObject, setPordObject] = useState({});
    const nav = useNavigate();
    const { language } = useContext(LanguageContext);

    useEffect(() => {

        getProducts();
        // setIsLoading(true);

    }, [refresh]);






    async function getProducts() {

        try {
            await fetchContext.authAxios.get("/products").then((res) => {
                console.log(res.data)
                if (res.data.message === 'Session Expired') {
                    auth.logout()
                }
                if (res.data === 'no records') {
                    setNoProd(true)
                    setIsLoading(false)

                } else {
                res.data.sort((a, b) => {
                    console.log(a.recordId)
                        const dateA = new Date(a.recordId);
                        const dateB = new Date(b.recordId);
                        return dateB - dateA;
                      });
   
                    setPordObject(res.data);
                    setFilterObject(res.data);
                    setNoProd(false)
              
         
                    setIsLoading(false)

                }

            });
        } catch (error) {
            console.log(error);
        }
    }

 

    function goToDetail(prData) {


        nav('/details', { state: prData })
    }

    function prodsearch(e) {
        // console.log("prject searc")
        // console.log(e.target.value)
        var x = e.target.value.toLowerCase();
        let t = prodObject.filter(prod => prod.fieldData['Supplier Item Num(1)'].toLowerCase().includes(x) || prod.fieldData.DateSubmitted.toLowerCase().includes(x));
        // console.log(t)
        setFilterObject(t)
        setRefresh(false);

    }

    return (
        <>
            {isLoading ? <>

                <p className="  text-center">Gathering your products.</p>
                <Spinner animation="border" variant="danger" />


            </> : (
                <>

                    {
                        prodObject.length >= 1 ? (
                            <>
                                <div className="text-center">
                                    <p class="mb-0 pb-0">{Lang.product_search[`${language}`]}</p> <br /> <input onInput={e => prodsearch(e)} id="projectsearch"
                                        class="mb-2 text-center inputsearch" placeholder="Item #, Date Submitted" maxlength="75" type="text"></input>

                                </div>
                                <div>
                                    <NewProd fresh={() => setRefresh(false)} />
                                </div>

                                {filterObject.map((pr, i) => (
                                    <Table striped  >
                                        <tbody>
                                            <tr>
                                                <Row className="mx-auto border">
                                                    <Col xs={3} md={3} className=""> <ImageSlider imgs={[pr.fieldData.ImageName1, pr.fieldData.ImageName2, pr.fieldData.ImageName3, pr.fieldData.ImageName4]} /></Col>
                                                    <Col xs={9} md={9} className="">

                                                        <Col md={6} className="d-block text-start float-start">
                                                            <Table className="product-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.supplier_name[`${language}`]}</td>
                                                                        <td className="content1">{pr.fieldData['Supplier Name']}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.supplier_item[`${language}`]}</td>

                                                                        <td> {pr.fieldData['Supplier Item Num(1)']} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.description[`${language}`]}</td>

                                                                        <td> {pr.fieldData['Description(1)']}</td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.fob_price[`${language}`]}</td>
                                                                        <td>{parseFloat(pr.fieldData.FOB_Price).toFixed(3) + " " + pr.fieldData.PriceType}</td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.Packaging[`${language}`]}</td>
                                                                        <td>{pr.fieldData['Packaging(1)']}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.date_submitted[`${language}`]}</td>
                                                                        <td>{pr.fieldData.DateSubmitted}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>

                                                        </Col>
                                                        <Col md={6} className="d-block text-start float-start">
                                                            <Table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.material[`${language}`]}</td>
                                                                        <td>{pr.fieldData.Material}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.item_size[`${language}`]}</td>
                                                                        <td>{pr.fieldData.CM_Inch}
                                                                            <tr>
                                                                                <td colspan="1">
                                                                                    <table className="dim-table">
                                                                                        <tbody>
                                                                                            <tr><td><span className="labelTxt ps-1 first">{Lang.length[`${language}`]}</span> {pr.fieldData.ItemSizeLength}</td>
                                                                                                <td><span className="labelTxt ps-1">{Lang.width[`${language}`]}</span> {pr.fieldData.ItemSizeWidth}</td>
                                                                                                <td><span className="labelTxt ps-1">{Lang.height[`${language}`]}</span> {pr.fieldData.ItemSizeHeight}</td>
                                                                                                <td><span className="labelTxt ps-1">{Lang.diameter[`${language}`]}</span> {pr.fieldData.ItemSizeDiameter}</td></tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.unit[`${language}`]}</td>
                                                                        <td>{pr.fieldData.Unit}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.fob_port[`${language}`]}</td>
                                                                        <td>{pr.fieldData.FOB_Port}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="labelTxt">{Lang.moq[`${language}`]}</td>
                                                                        <td>{pr.fieldData.MOQ}</td>
                                                                    </tr>

                                                                </tbody>
                                                            </Table>
                                                            <div>
                                                                <EditImage prData={pr.fieldData} onSuccess={false} refreshDetail={() => getProducts()}/>
                                                                <EditProd prData={pr.fieldData} onSuccess={() => getProducts()} />
                                                                <Button className='btn-red mb-2 float-end  btn-txt mx-1' onClick={() => goToDetail(pr)}>{Lang.view_details[`${language}`]}</Button>
                                                            </div>
                                                        </Col>

                                                    </Col>
                                                </Row>
                                            </tr>
                                        </tbody>

                                    </Table>
                                ))}
                            </>

                        ) : (
                            <>
                                <h2> We did not find any products.</h2>
                                <h5>Click the button below to submit a new product.</h5>
                                <NewProd />

                            </>


                        )}

                </>
            )
            }


        </>
    )
}