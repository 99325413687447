import React from "react";

export default function Loading() {

    return (
        <div class=" load-container">        <div className="scaling-dots">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        </div>
    )
}