import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Form, Col, Image } from "react-bootstrap";
import { TrashFill } from 'react-bootstrap-icons';
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { LanguageContext } from '../context/LanguageContext';
import Lang from '../utils/Lang'
import Loading from './Loading';
export default function EditProd({ prData, onSuccess,refreshDetail }) {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const auth = useContext(AuthContext);
    const { authState } = auth;;
    const fetchContext = useContext(FetchContext);
    const [loadingMsg, setLoadingMsg] = useState('')
    const { language } = useContext(LanguageContext);
    const [refresh, setRefresh] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [prodObject, setProdObject] = useState({
        description: prData["Description(1)"],
        supplierID: prData["Supplier Item Num(1)"],
        FOB_Price: prData.FOB_Price,
        unit: prData.Unit,
        packaging: prData["Packaging(1)"],
        material: prData.Material,
        CM_Inch: prData.CM_Inch,
        ItemSizeLength: prData.ItemSizeLength,
        ItemSizeWidth: prData.ItemSizeWidth,
        ItemSizeHeight: prData.ItemSizeHeight,
        ItemSizeDiameter: prData.ItemSizeDiameter,
        ProductionTime: prData.ProductionTime,
        recordID: prData.recordID
    });

    const [pictures, setPictures] = useState([])
    const [file, setFile] = useState([]);
    const [disableBtn, setDisableBtn] = useState(false)

    useEffect(() => {

        // console.log(prData)
        // console.log(prodObject)
    }, [prData, prodObject]);

    function handleInputChange(event) {

        const { name, value } = event.target;
        console.log(name, value)
        if (event.target.name === 'files') {
            if (event.target.files.length !== 0) {
                // console.log("file")
                // console.log(event.target.files)
                setFile([...file, event.target.files[0]]);
                // console.log(URL.createObjectURL(event.target.files[0]))
                setPictures([...pictures, { blob: URL.createObjectURL(event.target.files[0]), filename: event.target.files[0].name }])

                // console.log(file.length)
                if (file.length >= 3) {
                    setDisableBtn(true)
                }


            }

        } else {
            setProdObject({ ...prodObject, [name]: value });
        }

    }
    function deletefile(e) {
        // console.log(e.target.name)
        console.log(e)
        const name = e

        let pics = pictures.filter(picture => picture.filename !== name)
        let fils = file.filter(fil => fil.name !== name)
        setPictures(pics)
        setFile(fils)

        setDisableBtn(false)

    }

    async function handleSumbit(e) {
        e.preventDefault();

        setIsLoading(true)
        setLoadingMsg('Updating Database')
        try {
            await fetchContext.authAxios.post("/productupdate", prodObject).then((res) => {
                console.log(res)
                if (res.data.message === 'Session Expired') {
                    auth.logout()
                }
                setLoadingMsg("Success!")
                setTimeout(function () {
                    if (onSuccess) {
                        onSuccess()
                    }else{
                        refreshDetail()
                    }
                    handleClose()
                    setIsLoading(false)
                }, 2000);
                setRefresh(false);



            });
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            <Button className='btn-red mb-2 float-end  btn-txt' onClick={handleShow}>
            {Lang.edit_product[`${language}`]}
            </Button>
            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{Lang.edit_product[`${language}`]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ?
                        <>
                            <Loading />
                            <p className='text-center'>{loadingMsg}</p>
                        </>

                        : <>
                            <Form onSubmit={(e) => handleSumbit(e)} className="formTxt">


                                <Form.Group className='d-flex' controlId="formDesc">
                                    <Form.Label>{Lang.product_description[`${language}`]} *</Form.Label>
                                    <Form.Control
                                        value={prodObject.description}
                                        as="textarea"
                                        rows={1}
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Product Description"
                                        name="description"
                                    />
                                </Form.Group>



                                <Form.Group as={Col} className='d-flex' controlId="formSupplyNum">
                                    <Form.Label className="lableTxt">{Lang.supplier_item[`${language}`]}  *</Form.Label>
                                    <Form.Control
                                        autocomplete="off"
                                        required
                                        value={prodObject.supplierID}
                                        name="supplierID"
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Supplier Item #"
                                    />
                                </Form.Group>

                                <Form.Group className='d-flex' as={Col} controlId="formFOB">
                                    <Form.Label className="lableTxt">{Lang.fob_price[`${language}`]} *</Form.Label>
                                    <Form.Control

                                        autocomplete="off"
                                        onChange={(e) => handleInputChange(e)}
                                        value={prodObject.FOB_Price}
                                        required
                                        placeholder="FOB Price"
                                        name="FOB_Price"
                                    />

                                </Form.Group>

                                <Form.Group className='d-flex' as={Col} controlId="formGridIssue">
                                    <Form.Label className="lableTxt">{Lang.unit[`${language}`]}</Form.Label>
                                    <Form.Control
                                        autocomplete="off"
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Unit"
                                        name="unit"
                                        value={prodObject.unit}
                                    />
                                </Form.Group>
                                <Form.Group className='d-flex' as={Col} controlId="formGridIssue">
                                    <Form.Label className="lableTxt">{Lang.Packaging[`${language}`]} *</Form.Label>
                                    <Form.Control
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Packaging"
                                        name="packaging"
                                        value={prodObject.packaging}
                                    />
                                </Form.Group>


                                <Form.Group className='d-flex' controlId="ControlTextarea1">
                                    <Form.Label className="lableTxt">{Lang.material[`${language}`]}</Form.Label>
                                    <Form.Control
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Material"
                                        type='text'
                                        name="material"
                                        value={prodObject.material}
                                    />
                                </Form.Group>
                                <Form.Group className='d-flex' as={Col} >
                                    <Form.Label className="lableTxt">{Lang.item_size[`${language}`]} *</Form.Label>
                                    <Form.Check
                                        inline
                                        label={Lang.Centimeter[`${language}`]}
                                        value="Centimeter"
                                        onChange={(e) => handleInputChange(e)}
                                        name="CM_Inch"
                                        checked={prodObject.CM_Inch === "Centimeter" ? (true) : false}

                                    />
                                    <Form.Check
                                        inline
                                        label={Lang.Inch[`${language}`]}
                                        onChange={(e) => handleInputChange(e)}
                                        name="CM_Inch"
                                        value="Inch"
                                        checked={prodObject.CM_Inch === 'Inch' ? (true) : false}
                                    />

                                </Form.Group>
                                <Form.Group>
                                    <div className='d-flex text-center dimBox mb-2'>
                                        <Form.Group as={Col} controlId='dem' className="dimTxt">
                                            <Form.Label className="dimTxt  pe-4" style={{ maxWidth: '15px' }}>{Lang.length[`${language}`]}</Form.Label>
                                            <Form.Control
                                                className='text-center formInput'
                                                onChange={(e) => handleInputChange(e)}
                                                required
                                                type="text"
                                                placeholder="Length"
                                                name="ItemSizeLength"
                                                value={prodObject.ItemSizeLength}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId='dem' className="dimTxt" >
                                            <Form.Label className="lableTxt pe-4">{Lang.width[`${language}`]}</Form.Label>
                                            <Form.Control
                                                onChange={(e) => handleInputChange(e)}
                                                className='text-center formInput dim'
                                                type="text"
                                                placeholder="Width"
                                                name="ItemSizeWidth"
                                                required
                                                size='sm'
                                                value={prodObject.ItemSizeWidth}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId='dem' className="dimTxt" >
                                            <Form.Label className="lableTxt pe-4">{Lang.height[`${language}`]}</Form.Label>
                                            <Form.Control
                                                onChange={(e) => handleInputChange(e)}
                                                className='text-center formInput'
                                                type="text"
                                                placeholder="Height"
                                                name="ItemSizeHeight"
                                                required
                                                value={prodObject.ItemSizeHeight}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId='dem' className="dimTxt">
                                            <Form.Label className="lableTxt dimTxt pe-4">{Lang.diameter[`${language}`]}</Form.Label>
                                            <Form.Control
                                                onChange={(e) => handleInputChange(e)}
                                                className='text-center formInput'
                                                value={prodObject.ItemSizeDiameter}
                                                placeholder="Diameter"
                                                name="diameter"
                                                required
                                            />
                                        </Form.Group>
                                    </div>
                                </Form.Group>
                                <Form.Group className='d-flex' as={Col} controlId="formGridPassword">
                                    <Form.Label className="lableTxt">{Lang.production_time[`${language}`]}</Form.Label>
                                    <Form.Control
                                        onChange={(e) => handleInputChange(e)}
                                        value={prodObject.ProductionTime}

                                        placeholder="Production Time"
                                        name="ProductionTime"
                                    />

                                </Form.Group>
                                {/* 
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label className="lableTxt">Product Image *</Form.Label>
                            <Form.Text className="text-muted d-flex">
                                <text>Select up to 4 images</text>
                            </Form.Text>
                            <Form.Control name="files" type="file" disabled={disableBtn} onChange={(e) => handleInputChange(e)} />
                            <Form.Text className="text-muted">

                                <text> Only use images &lt; 1 mb in size (.jpg, .jpeg, .gif, .png, .tif, .tiff)</text>
                            </Form.Text>
                        </Form.Group>

                        {
                            pictures.length ? (pictures.map((file) => (
                            
                                <div style={{ display: 'inline-block', position: 'relative' }}>
                                 <Image className="img-fluid mx-1" src={file.blob} alt="Card image cap" style={{ maxWidth: '75px' }} />
                                <Button
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    fontSize: '12px', lineHeight: "1", padding: '2.5px 3px'
                                  }}
                                  variant="danger"
                                  onClick={() => deletefile(file.filename)}
                                >
                                  <TrashFill onClick={() => deletefile(file.filename)} />
                                </Button>
                              </div>               
                            // <div style={{ position: 'relative',   flexDirection:"column" }}>

                            //     <Button name={file.filename}
                            //         style={{
                            //             zIndex: '1', marginBottom: '8%', fontSize: '12px', lineHeight: "1", padding: '2.5px 3px', position: 'absolute',
                            //             top: 0,
                            //             right: 0,
                            //         }}
                            //         variant="danger">
                            //         <TrashFill onClick={() => deletefile(file.filename)} />
                            //     </Button>
                            //     <Image className="img-fluid" src={file.blob} alt="Card image cap" style={{ maxWidth: '75px' }} />


                            // </div>
                            
                            
                            ))) : ("")
                        } */}
                            </Form>
                            <text>* <strong>Required field</strong> <br />
                            {Lang.astrick[`${language}`]}</text>
                        </>}
                </Modal.Body>

                <Modal.Footer className='justify-content-between'>
                    {isLoading ? '' : <>                 <Button className='btn-red mb-2   btn-txt' onClick={handleClose}>
                        Cancel
                    </Button>
                        <Button className='btn-red mb-2 float-end btn-hover  btn-txt' onClick={(e) => handleSumbit(e)}>
                        {Lang.submit_new_changes[`${language}`]}
                        </Button></>}

                </Modal.Footer>
            </Modal>
        </>
    );
}
